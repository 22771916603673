<template>
  <section class="about">
    <div class="banner">
      <img src="@/assets/img/about/banner.jpg" alt="" />
    </div>
    <img src="@/assets/img/header/logo.png" alt="" class="icon" />
    <div class="title">特磊，专注出口贸易供应链管理服务的方案商。</div>
    <div class="explain">
      浙江特磊网络科技有限公司成立于2020年，专注于出口贸易企业的供应链管理系统的技术研发及服务，解决企业全球化进程中的供应链管理问题。特磊秉承立足中国、放眼全球的理念，以科学、严谨的态度，为企业出海提供强有力的科技服务。全贸通供应链管理系统为出口贸易企业提供轻量化服务，让您的企业花更少的钱，办更大的事。
    </div>
    <div class="mapBox">
      <div class="map">
        <a
          href="https://map.baidu.com/search/%E4%B8%87%E5%BA%AD%E5%A4%A7%E5%8E%A6/@12675562.205,2564516.51,19z?querytype=s&da_src=shareurl&wd=%E4%B8%87%E5%BA%AD%E5%A4%A7%E5%8E%A6&c=340&src=0&wd2=%E6%B7%B1%E5%9C%B3%E5%B8%82%E5%AE%9D%E5%AE%89%E5%8C%BA&pn=0&sug=1&l=12&b=(12636479.69,2530673.31;12759359.69,2591281.31)&from=webmap&biz_forward=%7B%22scaler%22:1,%22styles%22:%22pl%22%7D&sug_forward=f4baf3bb8533afdd07026f00&device_ratio=1"
          target="_blank"
          style="display: inline-block; font-size: 0px"
        >
          <img src="../assets/img/about/address.png" alt="" class="bmView" />
        </a>
      </div>
      <div class="mapInfo">
        <div>浙江特磊网络科技有限公司研发中心</div>
        <div><strong>联系邮箱</strong>：telei@msd1688.com</div>
        <div><strong>联系地址</strong>：广东省深圳市宝安区西乡街道万庭大厦</div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      lng: 113.865544,
      lat: 22.577901,
      location: {
        lng: 113.865544,
        lat: 22.577901,
      },
    };
  },
};
</script>
<style scoped lang="scss">
.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  .banner {
    margin-bottom: 80px;
  }
  .icon {
    width: 235px;
    margin-bottom: 20px;
  }
  .title {
    font-size: 20px;
    color: #333333;
    margin-bottom: 57px;
  }
  .explain {
    width: 1050px;
    text-align: center;
    font-size: 18px;
    color: #333333;
    line-height: 32px;
    margin-bottom: 80px;
  }
  .mapBox {
    display: flex;
    align-items: center;
    margin-bottom: 90px;
    .map {
      width: 573px;
      height: 330px;
      margin-left: 40px;
      .bmView {
        height: 100%;
      }
    }
    .mapInfo {
      font-size: 18px;
      color: #333333;
      margin-left: 20px;
      width: 490px;
      div {
        &:first-child {
          margin-bottom: 60px;
        }
        &:nth-child(2) {
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>
